<template>
  <div class='future-and-options-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='future-and-options-title'>Activate Future & Options</div>
              <div class='future-and-options-stepper'>Step 8</div>

              <div class='future-and-options-switch'>
                <span class='future-and-options-switch-text'>
                  Would you like to register for Future & Options?</span
                >
                <a-switch
                  size='small'
                  style='float: right; margin-top: 10px'
                  v-model:checked='futureOptionRequired'
                  @change='onAddFutureOption'
                  checked-children='Yes'
                  un-checked-children='No'
                  :disabled='disable'
                />
              </div>
              <div v-if='addFutureOptions'>
                <div class='future-options-select-segment-wrapper'>
                  <a-checkbox-group
                    v-model:value='segmentValues'
                    name='checkboxgroup'
                    :options='segmentOptions'
                    :disabled='disable'
                  />
                </div>
                <div class='future-and-options-upload-text'>
                  Upload last 6 months bank statement
                </div>
                <div class='future-and-options-upload-btn'>
                  <a-upload
                    v-model:file-list='bankFileList'
                    :max-Count='1'
                    accept='.pdf, image/png, image/jpeg, image/jpg'
                    @change='onChangeBankStatement'
                    :customRequest='onUploadBankStatement'
                    :before-upload='beforeUpload'
                  >
                    <a-button  :disabled='disable'
                    style='width: 100%; height: 44px; border: 1px solid #79a89b'>
                      <span style='float: left'> Upload File </span>
                      <cloud-upload-outlined style='font-size: 20px; float: right' />
                    </a-button>
                  </a-upload>
                  <div v-if='ifFileUpload' style='color: red; text-align: initial'>
                    Please upload File
                  </div>
                </div>
                <div class='future-and-options-document-password'>
                  <a-form-item v-bind='validateInfos.password'
                    :rules="[
                      { required: false },
                    ]"
                    >
                    <a-input-password
                      class='input-field'
                      v-model:value='modelRef.password'
                      placeholder='Enter Document Password*'
                      :disabled='disable'
                    />
                  </a-form-item>
                </div>
                <div class='future-and-options-document-password-bottom-text'>
                  *Required if income Proof document is password protected
                </div>
              </div>
              <div class='future-and-options-btn-wrapper'>
                <a-button type='primary' @click='onClickContinue'
                :loading="load">Continue</a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  reactive,
  toRefs,
} from 'vue';
import { Form } from 'ant-design-vue';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;
const segmentOptions = [
  {
    label: 'Equity F&O',
    value: 'EQUITY_FNO',
  },
  {
    label: 'Commodity',
    value: 'COMMODITY_FNO',
  },
  {
    label: 'Currency',
    value: 'CURRENCY_FNO',
  },
];
export default {
  components: {
    CloudUploadOutlined,
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const load = ref(false);
    const futureOptionRequired = ref(false);
    const addFutureOptions = ref(false);
    const ifFileUpload = ref(false);
    const bankFileList = ref([]);
    const bankUploadedLink = ref('');
    const state = reactive({
      segmentValues: ['EQUITY_FNO'],
    });
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {});
    });
    // const router = useRouter();
    const modelRef = reactive({
      password: '',
    });
    const rulesRef = reactive({
      password: [
        {
          required: false,
          message: 'Please enter',
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);

    const onAddFutureOption = () => {
      if (futureOptionRequired.value === true) {
        addFutureOptions.value = true;
      } else {
        addFutureOptions.value = false;
      }
    };
    const onChangeBankStatement = () => {
      if (bankFileList.value.length > 0) {
        ifFileUpload.value = false;
      }
    };
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        const message = 'Validation error';
        const description = 'Only png, jpg, and jpeg images and pdf are accepted';
        Operations.notificationError(message, description);
      }
      return isJpgOrPng;
    };

    const onUploadBankStatement = ({ file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'FNO_BANK_STATEMENT');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const message = null;
      const description = 'Uploading your bank statement...';
      Operations.showNotification(message, description);
      disable.value = true;
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          Operations.showNotification(message, description);
          disable.value = false;
          bankUploadedLink.value = uploadResponse.data.url;
        })
        .catch(() => {
          Operations.showNotification(message, description);
          bankFileList.value = [];
          disable.value = false;
        });
    };
    const onClickContinue = () => {
      if (futureOptionRequired.value) {
        if (state.segmentValues.length === 0) {
          const message = 'Validation error';
          const description = 'For activating FNO, you will have to select at aleast one among the following segments.';
          Operations.notificationError(message, description);
        } else if (bankFileList.value.length === 0) {
          ifFileUpload.value = true;
        } else {
          validate().then(() => {
            const data = {
              fnoRequired: futureOptionRequired.value,
              bankStatement: {
                link: bankUploadedLink.value,
                password: modelRef.password,
              },
              segments: state.segmentValues,
            };
            load.value = true;
            disable.value = true;
            services
              .activateFno(data)
              .then((response) => {
                load.value = false;
                store.dispatch('onboardingStore/updateStage', response.data.data.stage);
              })
              .catch(() => {
                load.value = false;
              });
          });
        }
      } else {
        const data = {
          fnoRequired: futureOptionRequired.value,
        };
        load.value = true;
        disable.value = true;
        services
          .activateFno(data)
          .then((response) => {
            load.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // router.push('/aadhaar/esign');
          })
          .catch(() => {
            load.value = false;
          });
      }
    };
    return {
      futureOptionRequired,
      onClickContinue,
      onAddFutureOption,
      addFutureOptions,
      validateInfos,
      resetFields,
      modelRef,
      bankFileList,
      onChangeBankStatement,
      onUploadBankStatement,
      ifFileUpload,
      beforeUpload,
      bankUploadedLink,
      segmentOptions,
      ...toRefs(state),
      disable,
      load,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/FutureOptions.scss';
</style>
